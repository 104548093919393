/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useRef } from 'react';
import { VITE_DATA_VORTEX_API_URL } from '../configs/config';
import useMeeting from 'hooks/useMeeting';
import { datadogLogs } from '@datadog/browser-logs';
import type { DyteParticipant } from '@dytesdk/web-core';

// Configuration constants
const CONFIG = {
  SAMPLE_RATE: 48000,
  BUFFER_SIZE: 48000 * 10,
  API_ENDPOINT: VITE_DATA_VORTEX_API_URL || 'http://localhost:9000',
  DEBUG: true,
  MAX_RETRIES: 3,
  RETRY_DELAY: 1000,
};

// Type definitions
interface AudioResources {
  context: AudioContext;
  source: MediaStreamAudioSourceNode;
  processor: AudioWorkletNode;
  track: MediaStreamTrack;
}

// Logger functions
const logger = {
  info: (message: string, data?: any) => {
    if (!CONFIG.DEBUG) return;
    datadogLogs.logger.info(
      `[TalkTime Info] ${message}` +
        (data ? JSON.stringify({ ...data, classId: data?.classId }) : ''),
    );
  },
  error: (message: string, error?: any) => {
    if (!CONFIG.DEBUG) return;
    datadogLogs.logger.error(
      `[TalkTime Error] ${message}` +
        (error ? JSON.stringify({ error: { ...error, classId: error?.classId } }) : ''),
    );
  },
};

// Helper function to optimize audio data transfer size without quality loss
const compressForTransfer = (audioBuffer: Float32Array) => {
  // Convert to 16-bit integer format for more efficient JSON serialization
  const int16Data = new Int16Array(audioBuffer.length);

  for (let i = 0; i < audioBuffer.length; i++) {
    // Convert normalized float (-1.0 to 1.0) to Int16 range without losing precision
    int16Data[i] = Math.floor(audioBuffer[i] * 32767);
  }

  // Use base64 encoding for the binary data which is more efficient than JSON array serialization
  const uint8View = new Uint8Array(int16Data.buffer);
  const binaryString = Array.from(uint8View)
    .map((byte) => String.fromCharCode(byte))
    .join('');
  const base64String = btoa(binaryString);

  return {
    data: base64String, // Send as base64 string instead of array of numbers
    metadata: {
      encoding: 'int16-base64',
      originalLength: audioBuffer.length,
      originalFormat: 'float32',
    },
  };
};

// Worker management - define as a module singleton to ensure only one worker exists
let audioWorker: Worker | null = null;

const getAudioWorker = (): Worker => {
  if (!audioWorker) {
    audioWorker = new Worker(new URL('./audioProcessingWorker.js', import.meta.url));

    // Handle cleanup on page unload
    window.addEventListener('beforeunload', () => {
      if (audioWorker) {
        audioWorker.terminate();
        audioWorker = null;
      }
    });
  }
  return audioWorker;
};

// Audio processing hook with Worker-based queue system
export const useStudentTalkTime = () => {
  const meetingContext = useMeeting();
  const { meeting, classId, joinedParticipants } = meetingContext;

  // Track resources that need cleanup
  const activeResources = useRef(new Map<string, AudioResources>());

  // Track which participants we've already set up handlers for
  const handledParticipants = useRef(new Set<string>());

  // Track audio update handlers
  const audioUpdateHandlersRef = useRef(
    new Map<string, (payload: { audioEnabled: boolean; audioTrack: MediaStreamTrack }) => void>(),
  );

  // Worker reference
  const workerRef = useRef<Worker | null>(null);
  const participantIdMapping = useRef(new Map<string, string>());

  const initializingParticipants = new Set<string>();

  // Create unique participant ID from Dyte participant
  const getParticipantId = (participant: DyteParticipant): string => {
    const userType = participant.presetName === 'group_call_host' ? 'tutor' : 'student';
    return `${userType}-${classId}-${participant.customParticipantId?.split('-').pop()}`;
  };

  // Clean up audio processing resources for a participant
  const cleanupAudioProcessing = useCallback(
    async (participantId: string): Promise<void> => {
      const resources = activeResources.current.get(participantId);
      if (!resources) return;

      try {
        // Disconnect the processor
        if (resources.processor) {
          try {
            resources.processor.disconnect();
            if (resources.processor.port) {
              resources.processor.port.close();
            }
          } catch (error) {
            // Ignore disconnection errors
          }
        }

        // Disconnect the source
        if (resources.source) {
          try {
            resources.source.disconnect();
          } catch (error) {
            // Ignore disconnection errors
          }
        }

        // Close the audio context - just check if it's already closed
        if (resources.context && resources.context.state !== 'closed') {
          try {
            await resources.context.close();
          } catch (error) {
            // Just log and continue
            logger.info('Error closing context', {
              error: error instanceof Error ? error.message : String(error),
              participantId,
              classId,
            });
          }
        }
      } catch (error) {
        // Log but don't let errors interrupt cleanup
        logger.error('Error during cleanup', {
          error: error instanceof Error ? error.message : String(error),
          participantId,
          classId,
        });
      } finally {
        // This is the critical part - ALWAYS clear the resources
        activeResources.current.delete(participantId);
      }
    },
    [classId],
  );

  // Process buffer via the worker queue system - UPDATED FOR OPTIMIZED TRANSFER
  const processBuffer = useCallback(
    (participantId: string, audioData: Float32Array, userType: string, userId: string): void => {
      if (!workerRef.current) return;

      const processingId = `${Date.now()}-${Math.random().toString(36).substring(2, 9)}`;

      // Encode audio data using more efficient transfer method
      const compressedTransfer = compressForTransfer(audioData);

      // Enqueue the audio processing request with transfer-optimized data
      workerRef.current.postMessage({
        action: 'enqueueAudio',
        participantId,
        processingId,
        audioData: compressedTransfer.data,
        transferMetadata: compressedTransfer.metadata,
        userType,
        userId,
      });
    },
    [],
  );

  // Add these useCallback wrappers before the useEffect
  const getParticipantIdCallback = useCallback(
    (participant: DyteParticipant): string => {
      const userType = participant.presetName === 'group_call_host' ? 'tutor' : 'student';
      return `${userType}-${classId}-${participant.customParticipantId?.split('-').pop()}`;
    },
    [classId],
  );

  const setupAudioProcessingCallback = useCallback(
    async (
      participant: DyteParticipant,
      audioTrack: MediaStreamTrack,
      retryCount = 0,
    ): Promise<void> => {
      const participantId = getParticipantIdCallback(participant);

      // Check if we're already initializing this participant
      if (initializingParticipants.has(participantId)) {
        logger.info('Already initializing audio for participant, skipping', {
          participantId,
          classId,
        });
        return;
      }

      // Mark as initializing
      initializingParticipants.add(participantId);
      const userType = participant.presetName === 'group_call_host' ? 'tutor' : 'student';

      try {
        // Validate audio track
        if (!audioTrack?.enabled || audioTrack.readyState !== 'live') {
          if (retryCount < CONFIG.MAX_RETRIES) {
            const delay = CONFIG.RETRY_DELAY * Math.pow(2, retryCount);
            setTimeout(() => {
              if (participant.audioEnabled && participant.audioTrack) {
                void setupAudioProcessingCallback(
                  participant,
                  participant.audioTrack,
                  retryCount + 1,
                );
              }
            }, delay);
          }
          return;
        }

        // Clean up any existing audio resources
        await cleanupAudioProcessing(participantId);

        // Create new audio context
        const context = new AudioContext({
          sampleRate: CONFIG.SAMPLE_RATE,
          latencyHint: 'interactive',
        });

        await context.resume();

        // Create the AudioWorklet processor script
        const moduleScript = `
      class TalkTimeProcessor extends AudioWorkletProcessor {
        constructor(options) {
          super();
          this.buffer = new Float32Array(${CONFIG.BUFFER_SIZE});
          this.position = 0;
          this.lastProcessTime = Date.now();
          
          const processorOptions = options.processorOptions || {};
          this.participantId = processorOptions.participantId;
          this.userType = processorOptions.userType;
          this.userId = processorOptions.userId;
          this.classId = processorOptions.classId;
        }

        process(inputs, outputs) {
          try {
            const input = inputs[0]?.[0];
            if (!input?.length) {
              this.port.postMessage({ type: 'error', message: 'No input data' });
              return true;
            }

            const currentTime = Date.now();
            
            for (let i = 0; i < input.length; i++) {
              if (this.position < this.buffer.length) {
                this.buffer[this.position++] = Math.max(-1, Math.min(1, input[i]));
              }
            }

            if (this.position >= this.buffer.length || 
                (currentTime - this.lastProcessTime >= 10000 && this.position > 0)) {
              this.port.postMessage({
                buffer: this.buffer.slice(0, this.position),
                participantId: this.participantId,
                userType: this.userType,
                userId: this.userId
              });
              
              this.position = 0;
              this.lastProcessTime = currentTime;
            }
          } catch (error) {
            this.port.postMessage({ 
              type: 'error', 
              message: 'Error in audio processing', 
              error: error.message 
            });
          }
          return true;
        }
      }

      registerProcessor('talk-time-processor-${participantId}', TalkTimeProcessor);
    `;

        const blob = new Blob([moduleScript], { type: 'text/javascript' });
        const scriptUrl = URL.createObjectURL(blob);

        try {
          await context.audioWorklet.addModule(scriptUrl);
          const stream = new MediaStream([audioTrack]);
          const source = context.createMediaStreamSource(stream);

          if (source.mediaStream.getAudioTracks().length === 0) {
            throw new Error('No audio tracks available');
          }

          const processor = new AudioWorkletNode(context, `talk-time-processor-${participantId}`, {
            numberOfInputs: 1,
            numberOfOutputs: 1,
            channelCount: 1,
            channelCountMode: 'explicit',
            channelInterpretation: 'discrete',
            processorOptions: {
              participantId,
              userType,
              userId: participant.customParticipantId,
              classId,
            },
          });

          processor.port.onmessage = (event) => {
            if (event.data.buffer) {
              processBuffer(
                event.data.participantId,
                new Float32Array(event.data.buffer),
                event.data.userType,
                event.data.userId,
              );
            } else if (event.data.type === 'error') {
              logger.error('Processor reported error', {
                message: event.data.message,
                error: event.data.error,
                participantId,
                classId,
              });
            }
          };

          // Start the processor port
          await processor.port.start();

          processor.onprocessorerror = (err) => {
            logger.info('Processor error', { error: err, participantId, classId });
          };

          // Connect audio nodes
          source.connect(processor);

          // Store resources for cleanup
          activeResources.current.set(participantId, {
            context,
            source,
            processor,
            track: audioTrack,
          });

          logger.info('Audio processing set up successfully', { participantId, classId });
        } finally {
          URL.revokeObjectURL(scriptUrl);
        }
      } catch (error) {
        logger.error('Error setting up audio processing', {
          error: error instanceof Error ? error.message : String(error),
          participantId,
          classId,
        });

        // Retry on failure
        if (retryCount < CONFIG.MAX_RETRIES) {
          const delay = CONFIG.RETRY_DELAY * Math.pow(2, retryCount);
          setTimeout(() => {
            if (participant.audioEnabled && participant.audioTrack) {
              void setupAudioProcessingCallback(
                participant,
                participant.audioTrack,
                retryCount + 1,
              );
            }
          }, delay);
        }
      } finally {
        // Always remove from initializing set
        initializingParticipants.delete(participantId);
      }
    },
    [classId, cleanupAudioProcessing, processBuffer, getParticipantIdCallback],
  );

  // Then update the monitoring useEffect to use these callbacks
  useEffect(() => {
    if (!meeting || !joinedParticipants?.length) return;

    // Check every 10 seconds that all participants have proper audio setup
    const intervalId = setInterval(() => {
      joinedParticipants.forEach((participant) => {
        if (participant?.audioEnabled && participant?.audioTrack) {
          const participantId = getParticipantIdCallback(participant);

          // If this participant should have audio processing but doesn't, set it up
          if (!activeResources.current.has(participantId)) {
            logger.info('Detected participant missing audio processing - setting up', {
              participantId,
              classId,
            });
            void setupAudioProcessingCallback(participant, participant.audioTrack, 0);
          }
        }
      });
    }, 10000);

    return () => clearInterval(intervalId);
  }, [
    joinedParticipants,
    meeting,
    getParticipantIdCallback,
    setupAudioProcessingCallback,
    classId,
  ]);

  // Initialize the worker
  useEffect(() => {
    if (!meeting) return;

    // Initialize worker if needed
    if (!workerRef.current) {
      workerRef.current = getAudioWorker();

      // Set up worker message handler
      workerRef.current.onmessage = (e) => {
        const {
          type,
          message,
          data,
          processingId,
          participantId,
          userType,
          userId,
          audioData,
          transferMetadata,
        } = e.data;

        switch (type) {
          case 'log':
            // Handle logs from worker
            if (e.data.logType === 'info') {
              logger.info(message, data);
            } else if (e.data.logType === 'error') {
              logger.error(message, data);
            }
            break;

          case 'processAudio':
            // Process audio data when worker tells us to
            if (processingId && participantId && audioData) {
              try {
                const validParticipants = joinedParticipants
                  .map((p) => p.name)
                  .filter((name): name is string => !!name);

                // Prepare the payload
                const request_id = `${userType}-${classId}-${userId}-${Date.now()}`;
                const payload = {
                  audio_data: audioData,
                  audio_metadata: transferMetadata,
                  session_id: participantId,
                  sample_rate: CONFIG.SAMPLE_RATE,
                  user_type: userType,
                  class_id: classId,
                  user_id: userId,
                  participant_names: validParticipants,
                  request_id: request_id,
                };

                // Debug log the audio data metrics
                logger.info('Audio data prepared for processing', {
                  processingId,
                  request_id,
                  transferFormat: transferMetadata?.encoding || 'legacy',
                  payloadSize: typeof audioData === 'string' ? audioData.length : 'legacy-format',
                  participants: validParticipants.length,
                  participantId,
                });

                // True fire and forget - don't even wait for the promise
                try {
                  // Log the endpoint we're sending to
                  logger.info('Sending audio to API endpoint', {
                    endpoint: `${CONFIG.API_ENDPOINT}/v1/process-audio`,
                    participantId,
                    classId,
                    payloadSizeBytes: JSON.stringify(payload).length,
                  });

                  const controller = new AbortController();
                  // Set a timeout to abort the request after 10 seconds to prevent hanging
                  const timeoutId = setTimeout(() => controller.abort(), 10000);

                  // Force use of fetch for better debugging
                  fetch(`${CONFIG.API_ENDPOINT}/v1/process-audio`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      'X-Audio-Transfer-Encoding': transferMetadata?.encoding || 'legacy',
                    },
                    body: JSON.stringify(payload),
                  })
                    .then((response) => {
                      // Clear the abort timeout
                      clearTimeout(timeoutId);
                      logger.info('API response received', {
                        status: response.status,
                        ok: response.ok,
                        participantId,
                        processingId,
                      });
                    })
                    .catch((err) => {
                      // Clear the abort timeout
                      clearTimeout(timeoutId);
                      logger.error('Fetch error during audio processing', {
                        error: err instanceof Error ? err.message : String(err),
                        participantId,
                        processingId,
                      });
                    });
                } catch (error) {
                  // Log but continue processing - don't let API issues stop audio processing
                  logger.error('API request setup error', {
                    processingId,
                    error: error instanceof Error ? error.message : String(error),
                    participantId,
                    classId,
                  });
                }

                logger.info('Audio sent for processing', {
                  processingId,
                  participantId,
                  classId,
                });
              } catch (error) {
                logger.info('Buffer processing error', {
                  processingId,
                  error: error instanceof Error ? error.message : String(error),
                  participantId,
                  classId,
                });
              } finally {
                // Always notify worker that processing is complete
                if (workerRef.current) {
                  workerRef.current.postMessage({
                    action: 'processingComplete',
                    participantId,
                  });
                }
              }
            }
            break;
        }
      };
    }

    return () => {
      // Worker cleanup is handled by the singleton pattern
    };
  }, [meeting, joinedParticipants]);

  // Handle participant changes
  useEffect(() => {
    if (!meeting || !joinedParticipants?.length) {
      return;
    }

    // Track current participants with more detailed logging
    const currentParticipantIds = new Set(joinedParticipants.map((p) => p.id));

    logger.info('Current participants in monitoring loop', {
      count: currentParticipantIds.size,
      participantIds: Array.from(currentParticipantIds),
      classId,
    });

    // Force check of audio status for all participants, even if we think we've handled them
    // This ensures we catch any participants that might have been missed
    joinedParticipants.forEach((participant) => {
      if (!participant?.id || participant.id === meeting.self.id) {
        return;
      }

      const participantId = getParticipantId(participant);

      // Log the participant's audio state
      logger.info('Checking participant audio state', {
        participantId,
        audioEnabled: participant.audioEnabled,
        hasAudioTrack: !!participant.audioTrack,
        trackState: participant.audioTrack?.readyState,
        hasBeenHandled: handledParticipants.current.has(participant.id),
        hasActiveResources: activeResources.current.has(participantId),
        classId,
      });

      // Always make sure this participant has been "handled" (event listeners set up)
      // regardless of their current audio state
      if (!handledParticipants.current.has(participant.id)) {
        handledParticipants.current.add(participant.id);

        // Create handler for audio updates
        const audioUpdateHandler = (payload: {
          audioEnabled: boolean;
          audioTrack: MediaStreamTrack;
        }) => {
          logger.info('Audio update event received', {
            participantId,
            audioEnabled: payload.audioEnabled,
            hasTrack: !!payload.audioTrack,
            trackState: payload.audioTrack?.readyState,
            classId,
          });

          if (payload.audioEnabled && payload.audioTrack) {
            // Set up audio processing when unmuted with valid track
            void setupAudioProcessingCallback(participant, payload.audioTrack, 0);
          } else {
            // Clean up when muted
            void cleanupAudioProcessing(participantId);
          }
        };

        // Register handler and log the registration
        participant.on('audioUpdate', audioUpdateHandler);
        logger.info('Registered audio update handler', {
          participantId,
          classId,
        });

        // Store the handler so we can remove it later
        audioUpdateHandlersRef.current.set(participant.id, audioUpdateHandler);
      }

      // CRITICAL FIX: Even if we've "handled" this participant before,
      // check their current audio state and set up processing if needed
      if (participant.audioEnabled && participant.audioTrack) {
        const hasActiveProcessing = activeResources.current.has(participantId);

        if (!hasActiveProcessing) {
          logger.info('Setting up audio processing for enabled participant', {
            participantId,
            wasHandledBefore: handledParticipants.current.has(participant.id),
            classId,
          });

          void setupAudioProcessingCallback(participant, participant.audioTrack, 0);
        }
      }
    });

    // Update the mapping for all current participants
    joinedParticipants.forEach((participant) => {
      if (participant?.id && participant.customParticipantId) {
        const customId = getParticipantId(participant);
        participantIdMapping.current.set(participant.id, customId);
      }
    });

    // Find participants that have left
    const removedParticipantIds = Array.from(handledParticipants.current).filter(
      (id) => !currentParticipantIds.has(id),
    );

    // Clean up removed participants
    removedParticipantIds.forEach((participantId) => {
      handledParticipants.current.delete(participantId);

      // Use the mapping to get the custom ID
      const customId = participantIdMapping.current.get(participantId);
      if (customId) {
        void cleanupAudioProcessing(customId);
        // Remove from mapping after cleanup
        participantIdMapping.current.delete(participantId);
      }
    });

    // Set up handlers for new participants
    joinedParticipants.forEach((participant) => {
      // Skip self-participant and already handled participants
      if (
        !participant?.id ||
        participant.id === meeting.self.id ||
        handledParticipants.current.has(participant.id)
      ) {
        return;
      }

      handledParticipants.current.add(participant.id);

      // Create handler for audio updates
      const audioUpdateHandler = (payload: {
        audioEnabled: boolean;
        audioTrack: MediaStreamTrack;
      }) => {
        const participantId = getParticipantId(participant);

        if (payload.audioEnabled && payload.audioTrack) {
          // Set up audio processing when unmuted with valid track
          void setupAudioProcessingCallback(participant, payload.audioTrack, 0);
        } else {
          // Clean up when muted
          void cleanupAudioProcessing(participantId);
        }
      };

      // Register handler
      audioUpdateHandlersRef.current.set(participant.id, audioUpdateHandler);
      participant.on('audioUpdate', audioUpdateHandler);

      // Initial setup if audio is enabled
      if (participant.audioEnabled && participant.audioTrack) {
        void setupAudioProcessingCallback(participant, participant.audioTrack, 0);
      }
    });

    // Cleanup function to remove handlers
    return () => {
      joinedParticipants.forEach((participant) => {
        if (!participant?.id) return;

        const handler = audioUpdateHandlersRef.current.get(participant.id);
        if (handler) {
          participant.off('audioUpdate', handler);
          audioUpdateHandlersRef.current.delete(participant.id);
        }
      });
    };
  }, [joinedParticipants.length, meeting, cleanupAudioProcessing, setupAudioProcessingCallback]);

  // Component unmount cleanup
  useEffect(() => {
    return () => {
      // Clean up all active resources
      const cleanupPromises = Array.from(activeResources.current.keys()).map((participantId) =>
        cleanupAudioProcessing(participantId),
      );

      Promise.all(cleanupPromises)
        .then(() => {
          logger.info('All participants cleaned up during unmount', { classId });
        })
        .catch((error) => {
          logger.error('Error during unmount cleanup', {
            error: error instanceof Error ? error.message : String(error),
            classId,
          });
        });
    };
  }, [cleanupAudioProcessing, classId]);

  return null;
};

export default useStudentTalkTime;
